import FieldType from '@admin/types/FieldType';
import FormFields from '@admin/types/FormFields';
import { NewsCategory } from '@common/clients/api';

const categoryOptions = (categories: NewsCategory[]) =>
    categories.map(({ isMedia, name, slug }: NewsCategory) => ({
        title: { translationKey: `${isMedia ? 'Video' : 'News'} - ${name}` },
        value: slug,
    }));

export const formFields = (categories: NewsCategory[]): FormFields => ({
    newsTitle: {
        type: FieldType.text,
        isMandatory: true,
        id: 'newsTitle',
    },
    category: {
        type: FieldType.select,
        defaultValue: categories.length ? categories[0].slug : undefined,
        options: categoryOptions(categories),
        id: 'category',
    },
});
